@font-face {
  font-family: "OpenSansBold";
  src: url("/fonts/OpenSansBold") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansBoldItalic";
  src: url("/fonts/OpenSansBoldItalic") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansExtraBold";
  src: url("/fonts/OpenSansExtraBold") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansExtraBoldItalic";
  src: url("/fonts/OpenSansExtraBoldItalic") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansItalic";
  src: url("/fonts/OpenSansItalic") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansLight";
  src: url("/fonts/OpenSansLight") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansLightItalic";
  src: url("/fonts/OpenSansLightItalic") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansMedium";
  src: url("/fonts/OpenSansMedium") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansMediumItalic";
  src: url("/fonts/OpenSansMediumItalic") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "OpenSansRegular";
  src: url("/fonts/OpenSansRegular") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansSemiBold";
  src: url("/fonts/OpenSansSemiBold") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "OpenSansBoldItalic";
  src: url("/fonts/OpenSansSemiBold") format("opentype");
  font-weight: 600;
  font-style: italic;
}