html,
body {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.CookieDeclaration {
  max-width: 900px;
  margin: auto;
}
.CookieDeclarationDialogText,
.CookieDeclarationIntro,
.CookieDeclarationLastUpdated {
  margin: 0 0 14px 0;
}
.CookieDeclarationType {
  display: block;
  margin: 12px 0 12px 0;
  padding: 8px 8px 0 8px;
  border: 1px solid #333333;
}
.CookieDeclarationTypeHeader {
  font-weight: bold;
}
.CookieDeclarationTypeDescription {
  margin: 2px 0 16px 0;
}
.CookieDeclarationTable {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 18px 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.CookieDeclarationTableHeader {
  font-weight: bold;
  border-bottom: 1px solid #777777;
  text-align: left;
  padding: 4px;
  overflow: hidden;
}
.CookieDeclarationTableHeader[dir="rtl"] {
  text-align: right;
}
.CookieDeclarationTableCell {
  text-overflow: ellipsis;
  word-wrap: break-word;
  border-bottom: 1px solid #777777;
  vertical-align: top;
  padding: 4px 4px 5px 4px;
}
#CookieDeclarationUserStatusLabelConsentId {
  text-overflow: ellipsis;
  word-wrap: break-word;
}
@media all and (max-width: 600px) {
  .CookieDeclaration table.CookieDeclarationTable tr td:nth-child(n + 5),
  .CookieDeclaration table.CookieDeclarationTable tr th:nth-child(n + 5),
  .CookieDeclaration
    table.CookieDeclarationTable
    colgroup
    col:nth-child(n + 5) {
    display: none;
  }
}
